@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team{
    justify-content: center;
    align-items: center;
  }
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

